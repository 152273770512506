html {
  font-size: 18px;
}

body {
  font-family: $font-stack-body;
  font-weight: 400;
  font-size: 1rem;
  color: lighten($color-dark, 10);
  line-height: 1.4;
}

a {
  text-decoration: none;
  color: $color-primary;
  &:hover {
    text-decoration: none;
    color: darken($color-primary, 10%)
  }
}

.block {
  &--visible {
    overflow: visible;
  }
}

figure {
  margin: 0;
  figcaption {
    font-size: 0.9rem;
    color: #9a9a9a;
  }
  img {
    display: flex;
    margin: auto;
  }
}

strong {
  font-weight:600;
}

blockquote {
  position: relative;
  margin-bottom: 0;
  &:before {
    content: "\201C";
  }
  p {
    max-width: 80%;
    margin: auto;
    font-size: 1.1rem;
  }
}

//=============================Headlines===============================
h1, h2, h3 {
  font-family: $font-headline;
  color: $color-primary;
  line-height: 1.3;
}
h1 {
  @include responsive(phone) {
    font-size: 3.2rem;
  }
  font-size: 2.2rem;
}

h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: $color-dark;
}

h3 {
  font-size: 1.5rem;
  font-family: $font-headline;
  color: $color-primary;
  font-weight: $light;
}


//=============================Text Colors===============================
.text {
  &--white {
    color: #fff !important;
  }
  &--grey {
    color: lightgrey;
  }
  &--center  {
    text-align: center;
  }
  &--intro {
    font-size: 1.5rem;
    line-height: 1.3;
  }
}

//=============================Margin & Padding===============================
.mt-default {
  @include responsive (landscape) {
    margin-top: $gap-default;
  }
  margin-top: $gap-default-mobile
}

.mb-default {
  @include responsive (landscape) {
    margin-bottom: $gap-default
  }
  margin-bottom: $gap-default-mobile
}

.my-default {
  @include responsive (landscape) {
    margin-bottom: $gap-default;
    margin-top: $gap-default
  }
  margin-bottom: $gap-default-mobile;
  margin-top: $gap-default-mobile
}

.py-default {
  @include responsive (landscape) {
    padding-bottom: $gap-default;
    padding-top: $gap-default
  }
  padding-bottom: $gap-default-mobile;
  padding-top: $gap-default-mobile
}

.pt-default {
  @include responsive (landscape) {
    padding-top: $gap-default
  }
  padding-top: $gap-default-mobile
}
.pb-default {
  @include responsive (landscape) {
    padding-bottom: $gap-default
  }
  padding-bottom: $gap-default-mobile
}

//=============================Background Colors===============================
.bg-white {
  background-color: #fff;
}
.bg-grey {
  background-color: $grey;
}

.bg-primary {
  background: linear-gradient(180deg, #000000 0%, #AF7940 100%)
}


.bg-secondary{
  background-color: $color-secondary;
}

.bg-dark {
  background-color: $color-dark;
}

//=============================Layout & Grid===============================


.content {
  &__boxed {
    max-width: 1000px;
    margin: auto;
  }
}
