html {
	font-size: 19px;
}


h1, h2, h3 {
	font-family: $font-headline;
	margin-top: 0;
}
h1 {
	@include responsive(phone) {
		font-size: 3.2rem;
	}
	font-size: 2.2rem;
}

h2 {
	@include responsive (phone) {
		font-size: 2.6rem
	}
	margin-bottom: 10px;
	font-size: 2rem;
}

h3 {
	font-size: 1.2rem;
	font-family: $font-headline;
	color: $color-primary;
	font-weight: $bold; 
}

.subheadline {
	font-weight: $bold;
	font-size: 0.9rem;
	letter-spacing: 0.1em;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	margin-bottom: 0.2rem;
}

#main {
	ul  {
		list-style-position: inside;
		list-style-type: disc;
		li {
			margin-bottom: 10px;
		}
	}
}

a {
	color: $color-primary;
	&:hover {
	text-decoration: none;
	color: darken($color-primary, 10%)
	}
}

.image {
	&__rounded {
		figure {
			display: flex;
			max-width: 160px;
			max-height: 160px;
			min-width: 100px;
			min-height: 100px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 5%;
		}
	}
}


figure {
	margin: 0;
	figcaption {
		font-size: 0.9rem;
		color: #9a9a9a;
	}
}

strong {
	font-weight:600;
}

blockquote {
	font-style: italic;
	position: relative;
	color: $color-primary;
	p {
		max-width: 100%;
		font-size: 2.2rem;
		line-height: 1.5;
		margin: auto;
	}
	cite {
		margin-top: 20px;
		color: $color-dark;
		display: flex;
		align-items: center;
		&:before {
			content: "";
			display: inline-block;
			height: 2px;
			width: 20px;
			border: dashed;
			margin-right: 5px;
		}
	}
}

.container {
	max-width: $container-width;
	overflow: visible;
}

.shadowed {
	box-shadow: $shadowed;
	overflow: visible;
	display: inline-block
}

.contact-form {
	background: #fff;
	padding: 40px;
}



.highlight {
	h2 {
		&::after {
			margin-top: 10px;
			content:"";
			width: 60px;
			height: 5px;
			display: block;
			background-color: $color-primary;
		}
	}
}

//gaps
.mt-default {
	@include responsive (landscape) {
		margin-top: $gap-default;
	}
	margin-top: $gap-default-mobile 
}

.mb-default {
	@include responsive (landscape) {
		margin-bottom: $gap-default
	}
	margin-bottom: $gap-default-mobile
}

.my-default {
	@include responsive (landscape) {
		margin-bottom: $gap-default;
		margin-top: $gap-default
	}
	margin-bottom: $gap-default-mobile;
	margin-top: $gap-default-mobile
}

.py-default {
	@include responsive (landscape) {
		padding-bottom: $gap-default;
		padding-top: $gap-default
	}
	padding-bottom: $gap-default-mobile;
	padding-top: $gap-default-mobile
}

.pt-default {
	@include responsive (landscape) {
		padding-top: $gap-default
	}
	padding-top: $gap-default-mobile
}
.pb-default {
	@include responsive (landscape) {
		padding-bottom: $gap-default
	}
	padding-bottom: $gap-default-mobile
}


/*========================Header======================*/




/*========================Main-Content======================*/


.row {
	&--flex {
		display: flex;
		& > * {
			display:flex; 
		}
	}
}

.shadowed {
    -webkit-box-shadow: 7px 9px 27px 6px rgba(0,0,0,0.15);
    -moz-box-shadow: 7px 9px 27px 6px rgba(0,0,0,0.15);
    box-shadow: 8px 5px 20px 1px rgba(0,0,0,0.15);
}

.block {
	&__visible {
		overflow: visible;
	}
}

.box {
	position: relative;
	overflow: visible;
	display: flex;
	flex-direction: column;
	padding: 20px;
	border-bottom: solid 10px $color-primary;
	box-shadow: $shadowed;
	margin-bottom: 40px;
	background: #fff;
	font-size: 0.9rem;
	&__bg {
		background-size: contain;
		background-repeat: no-repeat; 
		background-position: top center; 
	}
}


.linkbox {
	@include responsive (desktop) {
		margin: 0;
	}
	@include responsive (phone) {
		min-height: 450px;
	}
	min-height: 280px;
	overflow: hidden; 
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	transition: all .3s ease;
	position: relative;
	margin: 0 auto 20px auto;
	z-index: 1;
	opacity: 1;

	&:before {
		content: '';
		position: absolute;
		background: linear-gradient(0deg,$color-dark,rgba($color-dark, 0));
		height: 100%;
		display: block;
		width: 100%;
		top: 0;
		right: 0;
		z-index: -1;
		transition:all .3s;
	}
	&:hover {
		&:before {
				transition:all .3s;
		}
	}
	& > a {
		color: #fff;
		position: absolute;
		left: 0;
		right: 0;
		font-size: 0.9rem;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	h2 {
		color: #fff;
		padding: 40px;
	}
	&__teasertext {
		color: #fff;
		opacity: 0;
		position: absolute;
		bottom: -40px;
		padding: 40px;
	}
	svg {
		opacity: 0;
		position: absolute;
		right: 40px;
		bottom: 10%;
	}
}


.cta-banner {
	position: relative;
	&__textbox {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		color: #fff;
		left: 0;
		right: 0;
		margin: auto;
		p {
			@include responsive(phone) {
				font-size: 2rem;
			}
			font-size: 1.2rem;
		}
	}
}



.accordion {
	border-bottom: solid 1px #000;
	&__toggler {
		display: flex;
		justify-content: space-between;
		font-size: 1.5rem;
		padding: 20px 0;
		cursor: pointer;
		&:focus {outline: none}
	}
	&__content {
		//max-height: 0
		//transition: max-height 0.5s ease;
	}
	.ui-icon {
		display: none;
	}
	i {
		color: $color-primary;
	}
}


.news {
	&__latest {
		font-size: 0.9rem;
		&__inner {
			position: relative;
		}
		img {
			width: 100%;
			display: flex;
		}
		.ce_text {
			display: none;
		}
		h3 {
			font-size: 1.6rem;
			margin-top: 0;
		}
	}
	&__meta {
		display: flex;
		justify-content: space-between;
		display: none;
	}
	&__category {
		font-size: 13px;
		line-height: 18px;
		padding: 3px 8px; 
		color: $grey;
		border-radius: 2px;
		border: solid 1px #a3a3a3; 
	} 
	&__quotes {

		a {
			color: #fff;
		}
		h3 {
			margin: 0;
			font-size: 2rem;
			color: #fff;
		}
	}
	&__col {
		margin-bottom: 180px;
	}
	&__customer-title {
		font-weight: $bold;
		font-size: 1.45rem;
	}
}

.mod_newsreader .ce_text{
	max-width: 900px !important;
	margin: auto;
	margin-top: 20px;
}

.news__latest.even {
	figure {
		@include responsive(tablet) {
			margin-top: 60px;
		}
		margin-bottom: 20px;
		width: 100%;
	}
	.news__latest__overlay {
		position: absolute;
		bottom: 0;
		width: 100%;
		background: #fff;
		padding: 20px;
	}
}


.quote {
	&__wrapper {
		position: relative;
		&:after {
			content: "";
			background: $grey;
			position: absolute;
			width: 55%;
			height: 100%;
			display: block;
			bottom: 0;
			right: 0;
			z-index: -1;
		}
	}
}



/*
*******************
** Subpages Styling
*******************
*/


.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	box-sizing: border-box;
	transition-timing-function:linear!important; //Wichtig für kontinuierliches scrolling
}



////=========================Traurig

.section {
	&__intro {
		@include responsive(landscape) {
			padding-top: 120px !important;
		}
		overflow: hidden;
		position: relative;
	}
	&__history {
		background-image: url(/files/images/history_teaser.jpg);
		background-size: cover;
		position: relative;
		overflow: hidden;
		position: relative;
	}
}


.custom-heading {
	@include responsive (phone) {
		left: 0;
		width: 100%;
		transform: translateX(0);
	}
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;


	left: 50%;
	transform: translateX(-50%);
	width: 180%;
}

.history {
	&__teaser {
		background-image: url('/files/images/history_teaser.jpg');
		background-size: cover;
		position: relative;
		&__text {
			font-size: 1.7rem;
			color: $color-primary;
			@include responsive(phone) {
				font-size: 2.2rem;
			}
			@include responsive(tablet) {
				font-size: 2.8rem;
			}
		}
	}
}


.swiper-container {
	.swiper-pagination {
		@include responsive(phone) {
			display: block;
		}
		display: none;
		font-size: 3rem;
		width: fit-content;
		right: 4%;
		color: #fff;
		left: auto;
		position: absolute;
		opacity: 0.7;
	}
}

.service-slider {
	position: relative;
	&__text {
		@include responsive(phone) {
			padding: 50px;
			font-size: $font-size-base;
		}
		font-size: $font-size-small;
		padding: 20px;
		background: url('/files/images/texture.jpg');
		background-size: cover;
		height: 100%;
		color: #fff;
	}
	.col {
		padding: 0;
		flex-basis: auto;
	}
	&:after {
		content: "";
		position: absolute;
		height: 40%;
		width: 100%;
		background: #212121;
		z-index: -1;
		bottom: 0;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 500px;
	}
	.swiper-button-prev {
		display: none;
	}
	.swiper-slide:not(.swiper-slide-active){
		opacity: 0 !important;
	}

	.swiper-slide-active {
		opacity: 1 !important;
	}
}

.history-slider {
	overflow: visible;
	&__year {
		position: absolute;
		top: -80px;
		left: 0;
		right: 0;
		margin: auto;
		display: inline-block;
		text-align: center;
		font-style: italic;
		font-size: 6rem;
		color: #fff;
		-webkit-text-stroke: 1px $color-primary;
	}
	.swiper-slide, .swiper-container {
		overflow: visible;
	}
	.swiper-slide {
		background: #fff;
		border: solid 25px #fff;
		box-shadow: $shadowed;
		p {
			margin-top: 20px;
			min-height: 60px;
			height: 100%;
			@include image-caption;
		}
	}
	.swiper-scrollbar {
		bottom: -40px;
	}
}


.service {
	&__wrapper {
		font-size: 0.85rem;
		svg {
			height: 65px;
			width: 100%;
			fill: $color-dark;
		}
		.ce_text {
			margin-bottom: 25px;
		}
	}
}


.news {
	&__jobs {
		&__box {
			background: #fff;
			padding: 25px;
			font-size: $font-size-small;
			.image_container {
				margin-bottom: 20px;
			}
		}
	}
}

.team {
	&__box {
		margin-bottom: 10px;
		p {
			padding: 15px 0;
		}
	}
}


