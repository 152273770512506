/* --------------------------
Media-Queries
--------------------------- */

@media screen and (min-width: 992px) {
  ::-moz-selection { /* Code for Firefox */
    color: #fff;
    background: $color-secondary;
}

::selection {
    color: #fff;
    background: $color-secondary; 
}

/* width */
::-webkit-scrollbar {
    width: 15px;
    height: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $color-primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: darken($color-primary, 0.9);
}
}