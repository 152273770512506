.swiper-button-next, .swiper-button-prev {
  @include responsive(phone) {
    width: 70px;
    height: 70px;
  }
  width: 25px;
  height: 25px;
}

.swiper-button-next:after, .swiper-button-prev:after {
  content: none;
}

.swiper-button-next {
  background: get-icon('arrow-right', #fff) no-repeat;
  background-size: 100%;
}

.swiper-button-prev {
  background: get-icon('chevron-left', #fff) no-repeat;
  background-size: 100%;
  display: none;
}

.customers-swiper {
  overflow: visible;
  position: relative;
  .swiper-wrapper {
    align-items: center;
    max-width: 800px;
  }
  .swiper-button-next {
    height: 35px;
    width: 35px;
  }
  .swiper-button-next {
    @include responsive(landscape) {
      display: block;
      background: get-icon('arrow-right', grey) no-repeat;
      width: 35px;
      height: 35px;
      background-size: 100%;
      right: -10%;
      position: absolute;
    }
    display: none;
  }
}

.testimonials-swiper {
  flex-wrap: nowrap;
  overflow: visible;
  .swiper-pagination {
    top: auto !important;
    bottom:0;
  }

  .swiper-wrapper {
    height: auto;
  }
  .swiper-navigation {
    position: relative;
    height: 100px;
    max-width: 400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .swiper-navigation>* {
    position: relative;
    top: 0;
    margin: 0;
  }
}

.testimonials-swiper {
  h3 {
    padding: 20px 20px 0px 20px;
  }
  p {
    padding: 0 20px 20px 20px;
  }
}

.marquee-slider {
  .row {
    flex-wrap: nowrap; 
    align-items: center;
  }
  .swiper-wrapper {
    transition-timing-function: linear !important;
  }
}