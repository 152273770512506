


//=======================> Startseite Header
#header {
	position: relative;
	// margin-bottom: $gap-default;
}


.header__start {
	position: relative;
	max-height: 700px;
	.header__inner {
		position:absolute;
		bottom: 5%;
		transform: translateY(-5%);
		left: 0;
		right: 0;
		.header__headline {
			display: flex;
			flex-direction: column;
			h1{
				color: #fff;
				font-size: 2.2rem;
				display: block;
				position: relative;
				margin:0;
				overflow: hidden;
				visibility: visible;
				font-size: 1.8rem;
				@include responsive (phone) {
					font-size: 2rem;
				}
				@include responsive (tablet) {
					font-size: 2.5rem;
				}
				@include responsive (landscape) {
					font-size: 2.8rem;
				}
				@include responsive (desktop) {
					font-size: 3.2rem;
				}
				@include responsive (extralarge) {
					font-size: 3.8rem;
				}
				span {
					display: block;
				}
			}
			p {
				color: #fff;
				font-size: 1rem;
				margin-top: 20px;
				margin-bottom: 5px;
			} 
		}
	}
	.header__image {
		display: block;
		img {
			display: flex;
			width: 100%;
		}
	}
}

.header__sub {
	position: relative;
	.header__headline {
		color: $color-dark;
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		top: 50%;
		padding-bottom: 40px;
		h1{color: #fff; margin: 0}
	}
	img {
		width: 100%;
		display: flex;
	}
}
body:not(.no-header-image) {
	.main-navigation-wrapper {
		.logo {
			svg path, rect{
				fill: #fff;
			}
		}
	}
}
.no-header-image {
	.header__sub {
		min-height: 400px;
		background: none;
	}
	.header__headline {
		padding-bottom: 40px;
		h1 {
			color: $color-dark;
		}
	}
	.header__main-navigation .level_1 span {
		color: $color-dark;
	}
	.main-navigation-wrapper {
		background: none;
	}
	.mobile-navigation svg {
		fill: $color-primary;
	}
}

//=====================================> End


