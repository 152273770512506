.contao-cookiebar {
  padding: 0px;
}

.cookiebar_simple .cc-inner {
  background: $color-secondary;
  max-width: 100%;
  border-radius: 0px;
}

.cookiebar_simple .cc-info {
  text-align: left;
}

.cookiebar_simple .cc-info > p {
  font-size: 0.8rem;
  color: $color-dark;
}

.cookiebar_simple .cc-info > a {
  color: $color-dark;
}

.cc-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cc-footer .cc-btn {
  margin-bottom: 10px;
  border: none;
  border-radius: 0px;
  &.success {
    background: #000;
    color: #fff;
    &:hover {
      background: #000;
    }
  }
}